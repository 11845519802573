.my-component {
    margin: 20px;
    padding: 20px;
    background-color: #f7f7f7;
    position: relative;
  }
  
  .my-component iframe {
    width: 100%;
    height: 500px;
    border: 1px solid #ccc;
  }
  .my-component .resized-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Scale and crop the image to fit the container */
  }
  
  
  
  